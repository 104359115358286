@import "_variables";
@import "~bootstrap/scss/bootstrap";
@import "~react-phone-number-input/style.css";
@import '~animate.css/animate.css';
@import "_mixins";
@import '~react-loading-skeleton/dist/skeleton.css';

* {
  scrollbar-width: thin;
  scrollbar-color: #333333 #e7e5e5;
}

::-webkit-scrollbar-track
{
	background-color: #e7e5e5;
}

::-webkit-scrollbar
{
	width: 8px;
}

::-webkit-scrollbar-thumb
{
	background-color: #333333;
}

.PhoneInputInput {
	border: none;
}


html,
body {
	height: 100%;
	margin: 0;
}

body {
	position: relative;
	font-family: "Montserrat", sans-serif;
	margin: 0;
	padding: 0;
}

#__next {
	display: flex;
	flex-direction: column;
	margin: 0;
	height: 100%;
}

a, label {
	@include font-semibold;
}

label {
	color: #616161;
}

h1,h2,h3,h4,h5,h6 {
	@include font-extrabold;
}

label {
	@include font-semibold;
}

th {
	border-top: 0;
}

.bg-primary-dark {
	background-color: #571817;
}

.bg-primary-gradient {
	background: rgb(86, 24, 23);
  background: linear-gradient(
    90deg,
    rgba(86, 24, 23, 1) 0%,
    rgba(178, 36, 38, 1) 100%
  );
}

.nav-logo {
	height: $logo-max-height;
}

#social-icons {
	a {
		margin-right: 0.5rem;
	}

	img {
		width: 25px;
		height: auto;
	}
}

.font-weight-black {
	@include font-black;
}

.font-weight-semibold {
	@include font-semibold;
}

.font-weight-extrabold {
	@include font-extrabold;
}

.font-weight-medium {
	@include font-medium;
}

.font-weight-bold {
	@include font-bold;
}

.font-size-1 {
	font-size: 120% !important;
}

.font-size-n1 {
	font-size: 80% !important;
}

.font-size-n2 {
	font-size: 75% !important;
}

.navbar-dark {
	.show > .nav-link,
  .active > .nav-link,
  .nav-link.show,
  .nav-link.active {
    @include font-black;
  }
}

.border-radius-left-0 {
	border-top-left-radius: valid-radius(0) !important;
    border-bottom-left-radius: valid-radius(0) !important;
}

.border-radius-right-0 {
	border-top-right-radius: valid-radius(0) !important;
    border-bottom-right-radius: valid-radius(0) !important;
}

.border-radius-top-0 {
	border-top-left-radius: valid-radius(0) !important;
    border-top-right-radius: valid-radius(0) !important;
}

.border-radius-bottom-0 {
	border-bottom-left-radius: valid-radius(0) !important;
    border-bottom-right-radius: valid-radius(0) !important;
}

.border-radius-1 {
	border-radius: 1rem !important;
}

.border-radius-left-1 {
	border-top-left-radius: valid-radius(1rem) !important;
    border-bottom-left-radius: valid-radius(1rem) !important;
}

.border-radius-right-1 {
	border-top-right-radius: valid-radius(1rem) !important;
    border-bottom-right-radius: valid-radius(1rem) !important;
}

.border-radius-top-1 {
	border-top-left-radius: valid-radius(1rem) !important;
    border-top-right-radius: valid-radius(1rem) !important;
}

.border-radius-bottom-1 {
	border-bottom-left-radius: valid-radius(1rem) !important;
    border-bottom-right-radius: valid-radius(1rem) !important;
}

.btn-outline-light-custom {
	@include button-variant(transparent, #fff);
	border-radius: 15px;
	border-size: 5px;
	border-color: #6c757d57;
}

.btn-dark-custom {
	@include button-variant(#000, #000);
	border-radius: 10px;
	border-size: 5px;
}

.btn-outline-primary-custom {
	@include button-variant(transparent, #6c757d40, transparent, #982023, transparent, #982023);

	&:hover {
		box-shadow: $box-shadow-sm;
	}
}

.btn-yellow-custom {
	@include button-variant(#fcb54b, #fcb54b);
	color: #6a1311;
}

a.link-as-is {
	color: inherit;
	font-weight: inherit;
	
	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

.text-pre-wrap {
	white-space: pre-wrap;
}